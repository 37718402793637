import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaskedInput from 'react-text-mask';
import classNames from 'classnames';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { FormError, Popup, PopupBody, PopupFooter } from "../../controls";
import { FormFieldLabel } from "../../forms";
import { DropDownList, DropDownListItem } from "../../controls/DropDownList";
import { AppState } from "../../../types/state/AppState";
import { addToPortfolioActions } from "../../../actions/add-to-portfolio.actions";
import IconSVG from "../../../styles/svg-icons";
import { isRequesting, isRequestSuccess, moneyUtils, numericUtils, stringUtils } from "../../../utils";
import { yup, yupSchemaValidate } from "../../../validation/yup";
import { errorMessages, constants } from "../../../constants";
import { Preloader } from "../../common";
import { portfolioListActions } from "../../../actions/portfolio-list.actions";
import { usePortfolios } from "../../../effects/usePortfolios";
import { Table } from '../../bidding/common/table';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { PortfolioAgreementDisclaimerActionBlocker } from "../../portfolio-agreement-disclaimer/PortfolioAgreementDisclaimerActionBlocker";
import { CreatePortfolioButton } from "../../portfolio-agreement-disclaimer/CreatePortfolioButton";
import { PortfolioAgreementDisclaimerContentBlocker } from "../../portfolio-agreement-disclaimer/PortfolioAgreementDisclaimerContentBlocker";
import { Rating } from "../../../types/enums/Rating";
import { Currency } from "../../../types/enums/Currency";

export interface AddToPortfolioPosition {
    securityId?: number;
    ticker: string;
    isinCusip?: string;
    size: number;
    rating: Rating;
    currency: Currency;
}
interface Props {
    position: AddToPortfolioPosition;
    onClose?: () => void;
}

const newPortfolioKey = 'new-portfolio';

const sizeRange = { min: 10000, max: 999999999 };

const validationSchema = yup.object().shape({
    size: yup
        .mixed()
        .transform((value: any) => moneyUtils.parse(value))
        .test('check-required', errorMessages.empty, (value: any) => !stringUtils.isNullOrWhiteSpace(value))
        .test('check-valid', errorMessages.invalidValue, (size: any) => numericUtils.isNumber(size))
        .test(
            'check-range',
            errorMessages.invalidRangeSize(sizeRange.min, sizeRange.max),
            (size: any) => +size >= sizeRange.min && +size <= sizeRange.max
        )
});

export const AddToPortfolioPopup = ({ position, onClose }: Props) => {
    const dispatch = useDispatch();
    const portfloliosListState = usePortfolios();
    const portfolios = portfloliosListState.items;
    const isLoading = isRequesting(portfloliosListState.requestState);
    const selectedPortfolio = useSelector((state: AppState) => state.addToPortfolio.selectedPortfolio);
    const isSaving = useSelector((state: AppState) => state.addToPortfolio.isSaving);
    const responseError = useSelector((state: AppState) => state.addToPortfolio.error);
    const inProgress = isSaving || isLoading;

    const [securitySize, setSecuritySize] = React.useState('');
    const [securitySizeError, setSecuritySizeError] = React.useState('');
    const [isAddNewPortfolioNameVisible, setIsAddNewPortfolioNameVisible] = useState(false);
    const [newPortfolioName, setNewPortfolioName] = useState('');
    const [newPortfolioNameError, setNewPortfolioNameError] = useState('');

    const existingPortfolioSecurity = selectedPortfolio?.securities?.find(s => s.securityId === position.securityId);
    const isExistingSecuritySizeUnchanged = existingPortfolioSecurity != null && existingPortfolioSecurity.size === moneyUtils.parse(securitySize);

    useEffect(() => {
        if (!portfolios.length && isRequestSuccess(portfloliosListState.requestState)) {
            setIsAddNewPortfolioNameVisible(true)
        }
        if (portfolios.length && !selectedPortfolio && !isAddNewPortfolioNameVisible) {
            dispatch(addToPortfolioActions.setSelectedPortfolio(portfolios[0]));
        }
    }, [portfolios, portfloliosListState, selectedPortfolio, isAddNewPortfolioNameVisible, dispatch])

    const canSave =
        !inProgress &&
        responseError == null &&
        (selectedPortfolio != null || newPortfolioName) &&
        !securitySizeError &&
        moneyUtils.parse(securitySize) > 0 &&
        !isExistingSecuritySizeUnchanged &&
        !newPortfolioNameError;

    const validationSchemaNewPortfolioName = yup.object().shape({
        name: yup
            .string()
            .trim()
            .matches(constants.nonWhitespace)
            .required(errorMessages.empty)
            .max(64),
    })

    const validateSecuritySize = useCallback(async (size: string) => {
        const error: { size?: string } =
            await yupSchemaValidate(validationSchema, { size }, undefined, undefined, 'size') || {};
        setSecuritySizeError(error.size || '');
    }, [])

    React.useEffect(() => {
        if (existingPortfolioSecurity && existingPortfolioSecurity.size) {
            const { size } = existingPortfolioSecurity;
            setSecuritySize(String(moneyUtils.money(size)))
            validateSecuritySize(moneyUtils.money(size))
        } else {
            setSecuritySize('');
            setSecuritySizeError('');
        }
    }, [existingPortfolioSecurity, validateSecuritySize]);

    const getPortfolioDropDownItems = () => {
        const items = [...portfolios].sort((a, b) => a.name?.localeCompare(b.name)).map((p, index) => ({
            value: p.referenceName,
            text: p.name,
            selected: (p.referenceName === selectedPortfolio?.referenceName),
            payload: p
        }));
        return [
            ...items,
            { text: 'Create New Portfolio', value: newPortfolioKey, selected: isAddNewPortfolioNameVisible },
        ];
    }

    const validateNewPortfolioName = async (name: string) => {
        const error: { name?: string } =
            await yupSchemaValidate(validationSchemaNewPortfolioName, { name }, undefined, undefined, 'name')
        setNewPortfolioNameError(error.name || '')
    }

    const handleClose = () => {
        if (!inProgress) {
            if(onClose) {
                return onClose();
            }

            dispatch(addToPortfolioActions.reset());
            dispatch(portfolioListActions.reset());
        }
    }

    const handleSizeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSecuritySize(e.target.value);
        if (securitySizeError) {
            validateSecuritySize(e.target.value);
        }
    }

    const handleNewCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPortfolioName(e.target.value)
        validateNewPortfolioName(e.target.value)
    }

    const handlePortfolioChange = (item: DropDownListItem) => {
        if (item.value === newPortfolioKey) {
            setIsAddNewPortfolioNameVisible(true);
            dispatch(addToPortfolioActions.setSelectedPortfolio());
        } else {
            setIsAddNewPortfolioNameVisible(false);
            setNewPortfolioName('');
            dispatch(addToPortfolioActions.setSelectedPortfolio(item.payload));
            dispatch(addToPortfolioActions.storeError());
        }
    }

    const handlerBlur = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;
        setSecuritySize(value);
        if (value || securitySizeError) {
            validateSecuritySize(value);
        }
    }

    const getColumns = () => {
        const columnDefinitions: IColumnDefinition<AddToPortfolioPosition>[] = [{
            columnKey: 'isin',
            renderColumnHeaderContent: () => 'Identifier',
            renderColumnContent: entity => entity.isinCusip ?? constants.emptyPlaceholder,
            className: 'data-list-cell-sm',
        }, {
            columnKey: 'size',
            renderColumnHeaderContent: () => <>Size<span className="text-red">*</span></>,
            renderColumnContent: () => (
                <div className="form-item">
                    <MaskedInput
                        className={classNames('form-control', { 'is-invalid': securitySizeError })}
                        type="text"
                        mask={createNumberMask({ prefix: '', integerLimit: sizeRange.max })}
                        value={securitySize}
                        maxLength={11}
                        placeholder="Add size"
                        disabled={inProgress}
                        onChange={handleSizeChange}
                        onBlur={handlerBlur}
                    />
                    <FormError message={securitySizeError} />
                </div>
            ),
            className: 'data-list-cell-sm cell-input text-right',
        }, {
            columnKey: 'ticker',
            renderColumnHeaderContent: () => 'Ticker',
            renderColumnContent: entity => entity.ticker,
            className: 'data-list-cell-lg',
        }, {
            columnKey: 'currency',
            renderColumnHeaderContent: () => 'Ccy',
            renderColumnContent: entity => entity.currency,
            className: 'data-list-cell-xxs padding-l-0',
        }, {
            columnKey: 'rtg',
            renderColumnHeaderContent: () => 'Rtg',
            renderColumnContent: entity => entity.rating,
            className: 'data-list-cell-xxxs padding-l-0',
        }]

        return columnDefinitions.map(c => new ColumnBuilder(c))
    }

    const renderNewPortfolioNameInput = () => isAddNewPortfolioNameVisible && (
        <div className="form-item portfolio-name-cnt">
            <FormFieldLabel text="New Portfolio Name" required={true} />
            <input
                className={classNames('form-control', { 'is-invalid': !!newPortfolioNameError })}
                placeholder="Enter name"
                maxLength={64}
                value={newPortfolioName}
                onChange={handleNewCompanyNameChange}
            />
            <FormError message={newPortfolioNameError} />
        </div>
    )

    const handleClick = () => dispatch(
        addToPortfolioActions.addSecurityToPortfolio(
            position,
            moneyUtils.parse(securitySize),
            newPortfolioName
        ))

    return (
        <PortfolioAgreementDisclaimerContentBlocker disabled={!portfloliosListState.items.length} onCancel={handleClose}>
            <Popup modalClass="modal-add-to-portfolio" title={position.ticker} renderInBody={true} onClose={handleClose}>
                <Preloader inProgress={isSaving}>
                    <PopupBody>
                        <div className="form-item">
                            <FormFieldLabel text="Select Portfolio" required={true} />
                            <DropDownList
                                defaultSortingEnabled={false}
                                showLoadingProgress={inProgress}
                                disabled={inProgress}
                                items={getPortfolioDropDownItems()}
                                onChange={handlePortfolioChange}
                                renderItemContentCallback={(item) => item.value === newPortfolioKey ? (
                                    <button className="btn-link btn-create" disabled={inProgress}>
                                        <IconSVG name="plus-sm" width={12} height={12} />
                                        <span className="align-middle">Create New Portfolio</span>
                                    </button>
                                ) : item.text}
                            />
                        </div>
                        {renderNewPortfolioNameInput()}
                        <Table
                            className="data-list-striped"
                            columns={getColumns()}
                            dataItems={[position]}
                        />
                        {
                            isExistingSecuritySizeUnchanged &&
                            <div className="status-message alert">
                                <i className="icon icon-warning" />
                                <span className="status-message-cnt">
                                    {position.ticker} ({moneyUtils.amountToMM(existingPortfolioSecurity?.size)} MM)
                                    is already in {selectedPortfolio?.name}. Would you like to update the size?
                                </span>
                            </div>
                        }
                        {
                            responseError &&
                            responseError.error &&
                            <div className="status-message alert">
                                <i className="icon icon-warning" />
                                <span className="status-message-cnt">{responseError.error}</span>
                            </div>
                        }
                    </PopupBody>
                    <PopupFooter>
                        <button className="btn btn-ghost" disabled={inProgress} onClick={handleClose}>Cancel</button>
                        <PortfolioAgreementDisclaimerActionBlocker onConfirm={handleClick}>
                            <CreatePortfolioButton
                                className="btn btn-main"
                                disabled={!canSave}
                                onClick={handleClick}
                            >
                                Add
                            </CreatePortfolioButton>
                        </PortfolioAgreementDisclaimerActionBlocker>
                    </PopupFooter>
                </Preloader>
            </Popup>
        </PortfolioAgreementDisclaimerContentBlocker>


    );
};
